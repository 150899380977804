import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import FooterImage from "../components/footerImage";
import { useStaticQuery, graphql } from "gatsby";
import { navigate } from "@reach/router";

import footerImage from "./footerImage";

const Footer = () => {
  let dateobj = new Date();
  let year = dateobj.getFullYear();
  const { allWordpressWpApiMenusMenusItems } = useStaticQuery(
    graphql`
      query FooterQuery {
        allWordpressWpApiMenusMenusItems(filter: { name: { eq: "Footer" } }) {
          edges {
            node {
              name
              items {
                title
                object_slug
                object_id
                wordpress_children {
                  wordpress_id
                  title
                  object_slug
                  url
                }
              }
            }
          }
        }
        site {
          siteMetadata {
            address
            phone
            fax
            email
          }
        }
      }
    `
  );
  return (
    <footer>
      <div className="container">
        <ul>
          {allWordpressWpApiMenusMenusItems.edges[0].node.items.map((item, index) => {
            return (
              <li key={index}>
                {/* <Link to={`${item.url}`}>
                  <span>{item.title}</span>
                </Link> */}
                <a href={item.url}>{item.title}</a>

                {item.wordpress_children && (
                  <ul>
                    {item.wordpress_children.map((subitem, index) => (
                      <li key={index}>
                        {/* <Link to={`${subitem.url}`}>{subitem.title}</Link> */}
                        <a href={subitem.url}>{subitem.title}</a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            );
          })}
          <li>
            Social
            <ul className="icon-wrapper">
              <a
                href="https://www.facebook.com/ksgallp"
                target="_blank"
                className="elegant-icon-facebook"
              ></a>
              {/* <a
                href="https://www.linkedin.com/in/steven-kang-81aaa95/"
                className="elegant-icon-linkedin"
                target="_blank"
              ></a> */}
              <a
                href=" https://www.instagram.com/ksgallp/"
                className="elegant-icon-instagram"
                target="_blank"
              ></a>

              <a className="badge" href="http://www.cpaconnect.com/" target="_blank">
                Member of:
                <FooterImage />
              </a>
            </ul>
          </li>
        </ul>
        <div className="lower__footer">
          Copyright © {year} - KSGA, LLP, 17918 Pioneer Blvd., Suite 200, Artesia, CA 90701, USA |
          Call: (562) 865-2727 | Fax: (562) 865-2760 | Email:{" "}
          <a href="mailto:info@ksgallp.com">info@ksgallp.com</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
