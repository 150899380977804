import PropTypes from "prop-types";
import React, { useState } from "react";
import Image from "../components/image";
import { useStaticQuery, graphql, Link } from "gatsby";

const Header = ({ siteTitle, onClickedButton, onPopupClick }) => {
  const { allWordpressWpApiMenusMenusItems } = useStaticQuery(
    graphql`
      query MenuQuery {
        allWordpressWpApiMenusMenusItems(filter: { name: { eq: "Main" } }) {
          edges {
            node {
              name
              items {
                title
                object_slug
                object_id
                wordpress_children {
                  wordpress_id
                  title
                  object_slug
                }
              }
            }
          }
        }
      }
    `
  );
  // console.log("menu", allWordpressWpApiMenusMenusItems.edges.node);
  return (
    <>
      <header className="inverse">
        <div className="kl-header-bg"></div>
        <div className="container container__header">
          <div className="fxb-col fxb-basis-auto">
            <div className="fxb-row site-header-row site-header-main">
              <div className="fxb-col fxb fxb-start-x fxb-center-y fxb-center-x fxb-basis-auto fxb-grow-1 fxb-sm-full site-header-col-left site-header-main-left">
                <Link to="/" className="fxb fxb-grow-1">
                  <Image src="ksgallp-logo.png" />
                  <h1 className="fxb-grow-3"></h1>
                </Link>
              </div>
              <div className="fxb-col fxb fxb-center-x fxb-center-y fxb-basis-auto fxb-sm-half site-header-col-center site-header-main-center">
                <div className="nav__wrapper">
                  <div className="mainnav--sidepanel">
                    <div className="navbig">
                      <ul className="navigation">
                        {allWordpressWpApiMenusMenusItems.edges[0].node.items.map((item, index) => {
                          return (
                            <li key={index}>
                              <Link to={`/${item.object_slug}`}>
                                <span>{item.title}</span>
                              </Link>

                              {item.wordpress_children && (
                                <ul>
                                  {" "}
                                  {item.wordpress_children.map((subitem) => (
                                    <li key={item.wordpress_id}>
                                      <Link to={`/${subitem.object_slug}`}>{subitem.title}</Link>
                                    </li>
                                  ))}{" "}
                                </ul>
                              )}
                            </li>
                          );
                        })}
                        <li>
                          <Link to="/contact">Contact</Link>
                          {/* <a onClick={onPopupClick}>
                          <span>Contact</span>
                        </a> */}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <a className="btn btn-theme btn-showmenu" onClick={onClickedButton}>
                  <i className="flaticon-menu"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
