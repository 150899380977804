import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

const MobileNav = ({ onClickClosed, onPopupClick }) => {
  const { allWordpressWpApiMenusMenusItems } = useStaticQuery(
    graphql`
      query MobileMenuQuery {
        allWordpressWpApiMenusMenusItems(filter: { name: { eq: "Main" } }) {
          edges {
            node {
              name
              items {
                title
                object_slug
                object_id
                wordpress_children {
                  wordpress_id
                  title
                  object_slug
                }
              }
            }
          }
        }
      }
    `
  );
  return (
    <div className="mobile__wrapper">
      <a className="btn-toggle-canvas" onClick={onClickClosed}>
        <i className="flaticon-close"></i>
        <span>Close</span>
      </a>
      <ul className="mobile__navigation">
        {allWordpressWpApiMenusMenusItems.edges[0].node.items.map((item, index) => {
          return (
            <li key={index}>
              <Link to={`/${item.object_slug}`}>
                <span>{item.title}</span>
              </Link>
              <ul>
                {item.wordpress_children &&
                  item.wordpress_children.map((subitem) => (
                    <li key={item.wordpress_id}>
                      <Link to={`/${subitem.object_slug}`}>{subitem.title}</Link>
                    </li>
                  ))}
              </ul>
            </li>
          );
        })}
        <li>
          <Link to="/contact">Contact</Link>
          {/* <a onClick={onPopupClick}>
            <span>Contact</span>
          </a> */}
        </li>
      </ul>
    </div>
  );
};

export default MobileNav;
